import React from "react"
import { Link } from "gatsby"
import Image from "@/components/general/Image"
// import Layout from "@/components/layout"
// import Image from "@/components/image"
import SEO from "@/components/seo"
import Opener from "@/components/general/Opener"
import Sizing from "@/components/general/Sizing"
import Parallax from "@/components/general/Parallax"
import Heading from "@/components/general/Heading"
import BoredomGraph from "@/components/graphs/BoredomGraph"
import ColorChangeBG from "@/components/general/ColorChangeBG"
import Spacing from "@/components/general/Spacing"
import Signup from "@/components/general/Signup"
import Dropcap from "@/components/general/Dropcap"
import FooterNav from "@/components/general/FooterNav"
import Popout from "@/components/general/Popout"
import Motif from "@/components/general/Motif"
// import pullquote from "@/images/boredom/pullquote.svg"
import Footer from "@/components/general/Footer"
import Share from "@/components/general/Share"

import openerText from "@/images/climate/opener-text.png"
import openerTextSml from "@/images/climate/opener-text-sml.png"

import dcm from "@/images/climate/dcm.png"
import dcw from "@/images/climate/dcw.png"
import dca from "@/images/climate/dca.png"
import dct from "@/images/climate/dct.png"
import shareImg from "@/images/og/og_climate.jpg"

import SignupPopup from "@/components/general/SignupPopup"

const ClimatePage = ({pageContext}) => {
    return (
        <>
            <SEO
                page="/climate-change"
                title="Free Markets Destroy | Learn How Free Markets Destroy Climate Change"
                description="We need to take the threat of climate change seriously. Every day we're faced with news that seems more damning for the planet. But if you think the situation is hopeless, think again."
                img={shareImg}
            />

            <Opener
                pageName="climate"
                titles={[
                    { img: openerText, size: 14 },
                    { img: openerTextSml, size: 22 },
                ]}
            ></Opener>

            {
                // ======================================= SECTION 1 =======================================
            }

            <ColorChangeBG
                from={{ r: 255, g: 255, b: 255 }}
                to={{ r: 104, g: 197, b: 184 }}
            >
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 6,
                                width: 3,
                                marginTop: -4,
                            },
                        },
                    ]}
                >
                    <Motif page="climate" id="motif_3" movement={0.1} />
                </Sizing>
                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginRight: 2,
                                width: 20,
                                marginBottom: 3,
                                // marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginRight: 2,
                                width: 10,
                                marginBottom: 1,
                            },
                        },
                        { min: 1200, val: { marginLeft: 8, width: 10 } },
                    ]}
                >
                    <div className={"copy--lead"}>
                        <Dropcap img={dcw}>W</Dropcap>e need to take the threat
                        of climate change seriously. While the planet itself is
                        remarkably robust—it has survived innumerable ice ages,
                        volcanic eruptions, floods and droughts—human beings are
                        fragile. We need fresh water, arable land, and
                        biodiversity to survive. Every day we are faced with the
                        news that seems more damning for the planet—and our
                        futures.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 1,
                                width: 22,
                                paddingBottom: 0.3,
                                marginTop: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 0,
                                marginLeft: 1,
                                width: 16,
                                paddingBottom: 0.3,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <Image src="climate_flood" />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    top: 3,
                                    width: 2,
                                    right: -6,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_6" movement={0.1} />
                    </Sizing>
                </Sizing>

                <div style={{ float: "left", clear: "left" }}>
                    <SignupPopup></SignupPopup>
                </div>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 3,
                                width: 20,

                                marginTop: 0.5,
                                marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 3,
                                width: 16,
                                marginTop: 0,
                                marginBottom: 0,
                                // marginBottom: 1,
                            },
                        },
                    ]}
                >
                    <div className="caption">
                        - Destructive flooding in the southeastern U.S.
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 2,
                                width: 20,
                                marginTop: 1,
                                // marginTop: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 1,
                                marginLeft: 8,
                                width: 8,
                            },
                        },
                    ]}
                >
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        top: -2,
                                        width: 3,
                                        right: -6,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_2"
                                movement={-0.1}
                            />
                        </Sizing>

                        <p className="copy">
                            But if you think the situation is hopeless, think
                            again. In the past three centuries, free markets
                            have channeled human creativity toward solutions to
                            enormous challenges like hunger, poverty, and
                            disease. Markets created our modern global society,
                            with interdependent economies that encourage peace
                            and tolerance. If humanity is going to fend off
                            climate change, then it will be free markets that
                            make it happen.
                        </p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        bottom: 0,
                                        width: 2,
                                        left: -6,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_6"
                                movement={-0.05}
                            />
                        </Sizing>

                        <p className="copy">
                            Before we dive into some of the ways that free
                            markets are already tackling climate change, it’s
                            worth clearing up a few misconceptions. Since the
                            1980s, the largest reductions in greenhouse gas
                            emissions have actually come from free market
                            economies. Data shows that wherever markets are
                            constrained, the environment suffers.
                        </p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        top: 2,
                                        width: 1.5,
                                        left: -3.5,
                                    },
                                },
                            ]}
                        >
                            <Motif page="climate" id="cover_5" movement={0.1} />
                        </Sizing>

                        <p className="copy">
                            This phenomenon was predicted decades ago by the
                            Nobel Prize-winning economist Simon Kuznets. In the
                            1950s, Kuznets hypothesized that as an economy
                            develops, free market forces first increase and then
                            decrease economic inequality. Over time, as the
                            economy matures and per capita income goes up,
                            environmental health increases. The reason? When
                            you’re struggling to feed your family, you can’t
                            afford to take care of the environment. Clean air
                            and clean water may be essential to our survival,
                            but they’re also luxury goods for millions of
                            people. What the so-called Kuznets curve
                            demonstrates is that the best answer to pollution is
                            economic prosperity.
                        </p>
                    </div>
                </Sizing>
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 104, g: 197, b: 184 }}
                to={{ r: 209, g: 221, b: 38 }}
            >
                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                > */}

                <Sizing
                    styles={{ flexGrow: 0, flexShrink: 0 }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            },
                        },
                    ]}
                >
                    <Sizing
                        styles={{ flexGrow: 0, flexShrink: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 20,
                                    float: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                    marginTop: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: "auto",
                                    float: "none",
                                    marginLeft: 0,
                                    marginBottom: 0,
                                    marginTop: 0,

                                    paddingLeft: 1,
                                    flexBasis: 10,
                                },
                            },
                        ]}
                    >
                        <Image src="climate_forest" />
                    </Sizing>
                    <Sizing
                        styles={{ flexGrow: 0, flexShrink: 0 }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 20,
                                    float: "left",
                                    marginLeft: 2,
                                    marginBottom: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: "auto",
                                    float: "none",
                                    marginLeft: 0,
                                    marginBottom: 0,

                                    paddingLeft: 1,
                                    flexBasis: 8,
                                },
                            },
                        ]}
                    >
                        <div className={"copy"}>
                            <Dropcap img={dca}>A</Dropcap>
                            nother reason that free markets tend to produce
                            cleaner environments is private property rights. In
                            countries where property rights are strong,
                            landowners are incentivized to manage their
                            resources responsibly. If you’ve ever had a backyard
                            of your own, you know the feeling.
                        </div>

                        <p className="copy">
                            In America, 56% of all forested lands are privately
                            owned and yet we have more forestland than we did on
                            the first Earth Day more than 40 years ago. And
                            despite the fact that 91% of all the wood harvested
                            in the U.S. comes from these private forests, the
                            total timber stock on private land has doubled since
                            1953. That’s because individuals, families, and
                            companies who own the land have skin in the game,
                            they want to keep trees growing and replant what
                            they take so that they continue to get value from
                            it, whether that value is financial or purely
                            aesthetic.
                        </p>
                    </Sizing>
                </Sizing>

                {
                    // ======================================= SECTION 2 =======================================
                }
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 3,
                                marginBottom: 2,
                                marginTop: 2,
                                width: 12,
                            },
                        },
                    ]}
                >
                    <Heading>Saved by the Bits</Heading>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 2,
                                    width: 2,
                                    right: -4,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_1" movement={0.05} />
                    </Sizing>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: 0,
                                    width: 2,
                                    right: -5,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_1" movement={0.15} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 4,
                                width: 8,
                            },
                        },
                        ,
                        {
                            min: 1600,
                            val: {
                                marginLeft: 6,
                                width: 8,
                            },
                        },
                    ]}
                >
                    <div className={"copy"}>
                        <Dropcap img={dct}>T</Dropcap>he central claim of the
                        book More From Less, written by MIT economist Andrew
                        McAfee, is that free markets and technology are helping
                        developed nations use fewer resources to produce higher
                        levels of economic output. On one level, this
                        “dematerialization” is fairly straightforward: whereas
                        you used to need a camera, a calculator, a
                        dictionary—all of them made of physical materials like
                        wood and plastic—now you just need an iPhone. One device
                        replaces dozens. But market forces have encouraged this
                        process of dematerialization at every level.
                    </div>
                    <p className={"copy"}>
                        Let’s say you own a microbrewery. In 2020, the aluminum
                        cans you use to package your beers are likely to use
                        about 12% less metal than the cans of the 1980s. Why?
                        Because computer-aided modeling allows engineers to
                        design structurally perfect cans. And, crucially,
                        because if you decide not to use the more efficient
                        cans, your competitor down the road will, and she will
                        be able to lower her prices and steal some of your
                        customers. Competition and innovation are conspiring to
                        save the planet.
                    </p>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                marginLeft: 2,
                                marginTop: 4,
                                marginBottom: 4,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginLeft: 2,
                                width: 4,
                                minWidth: "350px",
                            },
                        },
                        {
                            min: 1200,
                            val: {
                                marginLeft: 3,
                                width: 4,
                                minWidth: "350px",
                            },
                        },
                    ]}
                >
                    <Signup color={"#E99245"}></Signup>
                </Sizing>

                {
                    // ======================================= END =======================================
                }
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 209, g: 221, b: 38 }}
                to={{ r: 255, g: 255, b: 255 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: 1,
                                width: 22,
                            },
                        },
                    ]}
                >
                    <Image src={"climate_loggers"} />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    bottom: -1.5,
                                    width: 0.8,
                                    right: 4,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_4" movement={-0.15} />
                    </Sizing>
                </Sizing>
                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                marginLeft: 2,
                                marginTop: -3,
                                // marginBottom: 1,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: -3,
                                marginLeft: 3,
                                width: 11,
                            },
                        },
                    ]}
                >
                    <Popout colors={["#D1DD26", "#0B1837"]}>
                        <Sizing
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        padding: 1,
                                    },
                                },
                            ]}
                        >
                            <div className={"copy--lead"}>
                                In America, 56% of all forested lands are
                                privately owned and yet we have more forestland
                                than we did on the first Earth Day more than 40
                                years ago. And despite the fact that 91% of all
                                the wood harvested in the U.S. comes from these
                                private forests, the total timber stock on
                                private land has doubled since 1953.
                            </div>
                        </Sizing>
                    </Popout>
                </Sizing>

                <Sizing
                    styles={{ float: "left", clear: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                display: "none",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "block",
                                marginLeft: 2,
                                width: 0.8,
                                marginTop: 2,
                            },
                        },
                    ]}
                >
                    <Motif page="climate" id="cover_4" movement={-0.2} />
                </Sizing>

                <Sizing
                    styles={{ float: "right" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginRight: 2,
                                marginTop: 2,
                                marginBottom: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                marginTop: 2,
                                marginRight: 3,
                                width: 9,
                            },
                        },
                    ]}
                >
                    <p className={"copy"}>
                        McAfee is no climate change skeptic. His mantra is,
                        “it’s warming; it’s us; it’s bad; and we can fix it.”
                        But he is an optimist who argues that the trends in the
                        developed world should give us hope for a brighter,
                        cleaner future. McAfee is confident that what he calls
                        the Four Horsemen of Optimism—technology, capitalism,
                        public awareness, and responsive government—will
                        continue to unleash the power of human creativity,
                        creating a digital economy of abundance. People will be
                        richer, the planet will be greener, but only if we let
                        free markets lead the way.
                    </p>
                </Sizing>
            </ColorChangeBG>
            <ColorChangeBG
                from={{ r: 255, g: 255, b: 255 }}
                to={{ r: 57, g: 171, b: 221 }}
            >
                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 5,
                                marginLeft: 3,
                            },
                        },
                    ]}
                >
                    <Heading navy={true} circle={false}>
                        MARKETS REQUIRE SUCCESS
                    </Heading>

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: 0,
                                    width: 0.8,
                                    top: -3,
                                },
                            },
                        ]}
                    >
                        <Motif page="climate" id="cover_4" movement={-0.1} />
                    </Sizing>
                </Sizing>

                <Sizing
                    styles={{ float: "left" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 20,
                                marginLeft: 2,
                                marginTop: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                width: 8,
                                marginLeft: 2,
                                marginTop: 3,
                            },
                        },
                    ]}
                >
                    <div className={"copy"} style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        right: -5,
                                        width: 3,
                                        top: -3,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_3"
                                movement={-0.1}
                            />
                        </Sizing>
                        <Dropcap img={dcm}>M</Dropcap>ost importantly for the
                        future, markets are proven. They have done more to cut
                        CO2 emissions than politicians and government. Across
                        America, cities like Seattle, New York, and Chicago
                        promised to cut emissions, but failed to meet their own
                        goals. Compare that to Microsoft, which pledges not only
                        to be carbon-neutral, but to eliminate CO2 equal to
                        their emissions since they began in 1975. Walmart
                        pledges to reduce one gigaton of CO2 emissions – more
                        than France’s total CO2 emissions for three years.
                    </div>

                    <div style={{ position: "relative" }}>
                        <Sizing
                            styles={{ position: "absolute" }}
                            breaks={[
                                {
                                    min: 0,
                                    val: {
                                        left: -5,
                                        width: 3,
                                        bottom: -3,
                                    },
                                },
                            ]}
                        >
                            <Motif
                                page="climate"
                                id="cover_3"
                                movement={-0.2}
                            />
                        </Sizing>

                        <p className="copy">
                            Markets reward people for conservation. Buy a Prius
                            and you’re not only helping the planet, you’re
                            saving money. Buy a smart thermostat, you can keep
                            your house comfortable with less electricity.
                            Markets hold people and businesses accountable for
                            environmental success. The same politicians who
                            failed to meet the last CO2 targets, on the other
                            hand, promise next time will be different. Climate
                            change is too important to trust to politicians who
                            may be out of office before society can measure
                            their success.
                        </p>
                    </div>
                </Sizing>

                <Sizing
                    styles={{ float: "left", position: "relative" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 22,
                                marginLeft: 1,
                                marginBottom: 3,
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                margin: 3,
                                width: 18,
                                marginBottom: 0,
                            },
                        },
                    ]}
                >
                    <Image src={"climate_tech"} />

                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -1,
                                    width: 1,
                                    top: -1,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_1" movement={0.1} />
                    </Sizing>
                    <Sizing
                        styles={{ position: "absolute" }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    right: -1,
                                    width: 0.6,
                                    bottom: 1,
                                },
                            },
                        ]}
                    >
                        <Motif page="disease" id="motif_2" movement={-0.1} />
                    </Sizing>
                </Sizing>
                <Spacing space="0" />
                <Sizing
                    breaks={[
                        {
                            min: 0,
                            val: {
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: 20,
                                maxWidth: "300px",
                                height: "50px",
                                marginTop: 2,
                                paddingBottom: 5,
                                position: "relative",
                                display: "block",
                            },
                        },
                        {
                            min: 1024,
                            val: {
                                display: "none",
                            },
                        },
                    ]}
                >
                    <Share></Share>
                </Sizing>
            </ColorChangeBG>
            {
                // ======================================= END =======================================
            }

            {/* <Spacing space="300vh" /> */}

            <Spacing space="0" />
            <FooterNav next={"boredom"} previous={"rents"} langKey={pageContext.langKey}/>
            <Footer></Footer>
        </>
    )
}

export default ClimatePage
